import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@konnektu/auth';
import { isDefined } from '@konnektu/helpers';
import { TableFullDto } from '@konnektu/metastore';
import { API_URL } from '@konnektu/tokens';
import { concatMap, filter, first, map, Observable, of } from 'rxjs';

export enum OperationType {
  Read,
  Update,
  Create,
  Delete
}

export type CacheKeyExtractor<T> = (o: T) => string;

export class InMemoryGenericCache<T, V> {
  private readonly _store = new Map<string, { original: T; value: V }>();
  private _extractor: CacheKeyExtractor<T>;

  constructor(extractor: CacheKeyExtractor<T>) {
    this._extractor = extractor;
  }

  generateKey(obj: T): string {
    return this._extractor(obj);
  }

  put(obj: T, toStore: V) {
    const key = this._extractor(obj);
    if (this._store.has(key)) {
      return;
    } else {
      this._store.set(key, { original: obj, value: toStore });
    }
  }

  get(obj: T) {
    const key = this._extractor(obj);
    if (this._store.has(key)) {
      return this._store.get(key);
    } else {
      return null;
    }
  }

  has(obj: T) {
    const key = this._extractor(obj);
    return this._store.has(key);
  }
}

export interface CheckAccessContext {
  Operation?: {
    Type?: OperationType;
    Name?: string;
  };
  Metastore?: {
    Table?: string;
    Column?: string;
  };
  Ui?: {
    Section?: string;
  };
}

export interface MetastoreRequestContext {
  Ui: {
    Section: string;
  };
  Operation: {
    Type: OperationType;
    Name: string;
  };
}

export interface TenantOperatorRoleDto {
  OperatorId: number;
  OperatorName: string;
  Roles: RoleDto[];
}

export interface OperatorDto {
  Id: number;
  Name: string;
}

export interface RoleTemplateDto {
  Id: number;
  Name: string;
  Policies: PolicyDto[];
}

export interface PolicyDto {
  Name: string;
  Condition: string;
}

export interface RoleDto {
  Id: number;
  Name: string;
  ParentId?: number;
  Policies: PolicyDto[];
}

@Injectable({ providedIn: 'root' })
export class AcsService {
  private readonly http = inject(HttpClient);

  private readonly apiUrl = inject(API_URL);

  private readonly authService = inject(AuthService);

  private readonly _cache = new InMemoryGenericCache<
    CheckAccessContext,
    boolean
  >((obj) => {
    if ('Metastore' in obj || 'metastore' in obj) {
      return `[${obj.Ui?.Section ?? 'none'}:${obj.Operation?.Name ?? 'none'}:${
        obj.Operation?.Type ?? 'none'
      }:${obj.Metastore?.Column ?? 'none'}:${obj.Metastore?.Table ?? 'none'}]`;
    } else {
      return `[${obj.Ui?.Section ?? 'none'}:${obj.Operation?.Name ?? 'none'}:${
        obj.Operation?.Type ?? 'none'
      }]`;
    }
  });

  hasAccess(contexts: CheckAccessContext[]): Observable<boolean[]> {
    return of(contexts).pipe(
      map((context) =>
        context.map((c) => (this._cache.has(c) ? this._cache.get(c)?.value : c))
      ),
      concatMap((partialGetFromCache) => {
        const toRequest = partialGetFromCache.filter(
          (c) => typeof c !== 'boolean'
        ) as CheckAccessContext[];

        if (toRequest.length) {
          return this.http
            .post<boolean[]>(`${this.apiUrl}/acs/check`, toRequest)
            .pipe(
              map((res) => {
                const constructedFromResult = res.map((hasAccess, index) => ({
                  obj: toRequest[index],
                  hasAccess
                }));
                return partialGetFromCache.map((getFromCacheOrContext) => {
                  if (typeof getFromCacheOrContext === 'boolean') {
                    return getFromCacheOrContext;
                  } else {
                    const requestedResult = constructedFromResult.find(
                      (v) => v.obj === getFromCacheOrContext
                    );
                    if (requestedResult) {
                      this._cache.put(
                        requestedResult.obj,
                        requestedResult.hasAccess
                      );
                      return requestedResult.hasAccess;
                    }
                    return true;
                  }
                });
              })
            );
        }
        return of(partialGetFromCache as boolean[]);
      })
    );
  }

  getAllRoles() {
    return this.http.get<RoleDto[]>(`${this.apiUrl}/acs/roles`);
  }

  getRole(id: number) {
    return this.http.get<RoleDto>(`${this.apiUrl}/acs/roles/${id}`);
  }

  getRoleTemplates() {
    return this.http.get<RoleTemplateDto[]>(
      `${this.apiUrl}/acs/role-templates`
    );
  }

  getAppliedRoles() {
    return this.http.get<TenantOperatorRoleDto[]>(
      `${this.apiUrl}/acs/applied-roles`
    );
  }

  getOperators() {
    return this.http.get<OperatorDto[]>(`${this.apiUrl}/acs/operators`);
  }

  getTenantOperatorRoles() {
    return this.authService.currentOperatorId$.pipe(
      filter(isDefined),
      first(),
      concatMap((operatorId) =>
        this.http.get<RoleDto[]>(
          `${this.apiUrl}/acs/operator-roles/${operatorId}`
        )
      )
    );
  }

  createNewRole(request: {
    roleTemplateId?: number;
    name: string;
    parentId?: number;
  }) {
    return this.http.post<boolean>(`${this.apiUrl}/acs/roles`, {
      ...request,
      policies: []
    });
  }

  updateRole(id: number, request: { name: string; parentId: number }) {
    return this.http.put<boolean>(`${this.apiUrl}/acs/roles/${id}`, request);
  }

  updateRolePolicies(
    id: number,
    request: { policies: { name: string; condition: string }[] }
  ) {
    return this.http.put<boolean>(
      `${this.apiUrl}/acs/roles/${id}/policies`,
      request
    );
  }

  applyRoles(operatorId: number, roleId: number) {
    return this.http.post<boolean>(`${this.apiUrl}/acs/roles/${roleId}/apply`, [
      operatorId
    ]);
  }

  denyRoles(operatorId: number, roleId: number) {
    return this.http.delete<boolean>(
      `${this.apiUrl}/acs/roles/${roleId}/deny`,
      {
        body: [operatorId]
      }
    );
  }

  getTables(context: MetastoreRequestContext) {
    return this.http.post<TableFullDto>(`${this.apiUrl}/acs/tables`, context);
  }
}
