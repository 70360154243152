import * as i0 from '@angular/core';
import { EventEmitter, Directive, HostBinding, Input, Output, HostListener, NgModule } from '@angular/core';
class TuiCheckedDirective {
  constructor() {
    this.indeterminate = false;
    this.checked = false;
    this.tuiCheckedChange = new EventEmitter();
  }
  get isChecked() {
    return this.checked;
  }
  get isIndeterminate() {
    return this.indeterminate;
  }
  set tuiChecked(checked) {
    this.checked = checked || false;
    this.indeterminate = checked === null;
  }
  onChange({
    checked
  }) {
    this.checked = checked;
    this.indeterminate = false;
    this.tuiCheckedChange.emit(checked);
  }
}
TuiCheckedDirective.ɵfac = function TuiCheckedDirective_Factory(t) {
  return new (t || TuiCheckedDirective)();
};
TuiCheckedDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiCheckedDirective,
  selectors: [["input", "tuiChecked", ""], ["input", "tuiCheckedChange", ""]],
  hostVars: 2,
  hostBindings: function TuiCheckedDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function TuiCheckedDirective_change_HostBindingHandler($event) {
        return ctx.onChange($event.target);
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("checked", ctx.isChecked)("indeterminate", ctx.isIndeterminate);
    }
  },
  inputs: {
    tuiChecked: "tuiChecked"
  },
  outputs: {
    tuiCheckedChange: "tuiCheckedChange"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCheckedDirective, [{
    type: Directive,
    args: [{
      selector: 'input[tuiChecked], input[tuiCheckedChange]'
    }]
  }], null, {
    isChecked: [{
      type: HostBinding,
      args: ['checked']
    }],
    isIndeterminate: [{
      type: HostBinding,
      args: ['indeterminate']
    }],
    tuiChecked: [{
      type: Input
    }],
    tuiCheckedChange: [{
      type: Output
    }],
    onChange: [{
      type: HostListener,
      args: ['change', ['$event.target']]
    }]
  });
})();
class TuiCheckedModule {}
TuiCheckedModule.ɵfac = function TuiCheckedModule_Factory(t) {
  return new (t || TuiCheckedModule)();
};
TuiCheckedModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiCheckedModule
});
TuiCheckedModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCheckedModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiCheckedDirective],
      exports: [TuiCheckedDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiCheckedDirective, TuiCheckedModule };
