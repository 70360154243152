import * as i0 from '@angular/core';
import { Directive, Inject, NgModule } from '@angular/core';
import { TuiFocusVisibleService, TuiDestroyService } from '@taiga-ui/cdk/services';
import * as i1 from 'rxjs';

/**
 * Directive to imitate :focus-visible
 * (https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible)
 * in browsers that do not support it
 */
class TuiFocusVisibleDirective {
  constructor(tuiFocusVisibleChange) {
    this.tuiFocusVisibleChange = tuiFocusVisibleChange;
  }
}
TuiFocusVisibleDirective.ɵfac = function TuiFocusVisibleDirective_Factory(t) {
  return new (t || TuiFocusVisibleDirective)(i0.ɵɵdirectiveInject(TuiFocusVisibleService));
};
TuiFocusVisibleDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiFocusVisibleDirective,
  selectors: [["", "tuiFocusVisibleChange", ""]],
  outputs: {
    tuiFocusVisibleChange: "tuiFocusVisibleChange"
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService, TuiFocusVisibleService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusVisibleDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiFocusVisibleChange]',
      outputs: ['tuiFocusVisibleChange'],
      providers: [TuiDestroyService, TuiFocusVisibleService]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiFocusVisibleService]
      }]
    }];
  }, null);
})();
class TuiFocusVisibleModule {}
TuiFocusVisibleModule.ɵfac = function TuiFocusVisibleModule_Factory(t) {
  return new (t || TuiFocusVisibleModule)();
};
TuiFocusVisibleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFocusVisibleModule
});
TuiFocusVisibleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusVisibleModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiFocusVisibleDirective],
      exports: [TuiFocusVisibleDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFocusVisibleDirective, TuiFocusVisibleModule };
