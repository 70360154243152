import * as i0 from '@angular/core';
import { ElementRef, NgZone, Directive, Inject, Output, NgModule } from '@angular/core';
import { tuiTypedFromEvent, tuiZoneOptimized } from '@taiga-ui/cdk/observables';
import { tuiIsNativeFocused } from '@taiga-ui/cdk/utils/focus';
import { merge } from 'rxjs';
import { map, startWith, distinctUntilChanged, skip } from 'rxjs/operators';

/**
 * Directive to monitor focus/blur status, works with focusIn/focus-out
 * instead of focus/blur to sync events order with Internet Explorer and
 * other focus related directives that require bubbling
 */
class TuiFocusedDirective {
  constructor({
    nativeElement
  }, zone) {
    this.tuiFocusedChange = merge(tuiTypedFromEvent(nativeElement, 'focusin'), tuiTypedFromEvent(nativeElement, 'focusout')).pipe(map(() => tuiIsNativeFocused(nativeElement)), startWith(false), distinctUntilChanged(), skip(1), tuiZoneOptimized(zone));
  }
}
TuiFocusedDirective.ɵfac = function TuiFocusedDirective_Factory(t) {
  return new (t || TuiFocusedDirective)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(NgZone));
};
TuiFocusedDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiFocusedDirective,
  selectors: [["", "tuiFocusedChange", ""]],
  outputs: {
    tuiFocusedChange: "tuiFocusedChange"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusedDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiFocusedChange]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }];
  }, {
    tuiFocusedChange: [{
      type: Output
    }]
  });
})();
class TuiFocusedModule {}
TuiFocusedModule.ɵfac = function TuiFocusedModule_Factory(t) {
  return new (t || TuiFocusedModule)();
};
TuiFocusedModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFocusedModule
});
TuiFocusedModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusedModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiFocusedDirective],
      exports: [TuiFocusedDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFocusedDirective, TuiFocusedModule };
